import "./sentry.ts";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme.tsx";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Home } from "./components/Home.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/rsvp",
    lazy: () => import("./pages/Rsvp.tsx"),
  },
  {
    path: "/rsvp/confirmation",
    lazy: () => import("./pages/RsvpConfirmation.tsx"),
  },
  {
    path: "/rsvp/not-attending",
    lazy: () => import("./pages/RsvpNotAttending.tsx"),
  },
  {
    path: "/admin/party",
    lazy: () => import("./pages/admin/Party.tsx"),
  },
  {
    path: "/admin/party/:partyId",
    lazy: () => import("./pages/admin/PartyPartyId.tsx"),
  },
  {
    path: "/auth/login",
    lazy: () => import("./pages/auth/Login.tsx"),
  },
]);

const queryClient = new QueryClient();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
