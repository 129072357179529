import { Divider, Grid2, Typography } from "@mui/material";
import logo from "../assets/images/logo.png";
import { Link as RouterLink } from "react-router";
import Link from "@mui/material/Link";

export function Home() {
  return (
    <Grid2
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid2 size={{ xs: 4, sm: 3, md: 2 }}>
        <img
          src={logo}
          alt="Partybook logo (an envelope)."
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </Grid2>
      <Grid2 size={{ xs: 8, sm: 6, md: 4 }}>
        <Typography variant="h2" align="center" component="h1">
          Partybook
        </Typography>
      </Grid2>
      <Grid2
        size={{ xs: 8, sm: 6, md: 4 }}
        sx={{
          display: "inline-flex",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <Link component={RouterLink} to="/auth/register">
          Register
        </Link>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Link component={RouterLink} to="/auth/login">
          Login
        </Link>
      </Grid2>
    </Grid2>
  );
}
